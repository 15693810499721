// ---------------------------------------------------------------
// Font Family Map
// Map to handle the font families
// ---------------------------------------------------------------

$font-family: (
  roman: $font-family-roman
);

// ---------------------------------------------------------------
// Font Size Map
// Map to handle the font sizes
// ---------------------------------------------------------------

$font-size: (
  xs: (
    small: $font-size-xs-mobile,
    large: $font-size-xs-desktop
  ),
  s: (
    small: $font-size-s-mobile,
    large: $font-size-s-desktop
  ),
  m: (
    small: $font-size-m-mobile,
    large: $font-size-m-desktop
  ),
  l: (
    small: $font-size-l-mobile,
    large: $font-size-l-desktop
  ),
  xl: (
    small: $font-size-xl-mobile,
    large: $font-size-xl-desktop
  ),
  2xl: (
    small: $font-size-2xl-mobile,
    large: $font-size-2xl-desktop
  ),
  3xl: (
    small: $font-size-3xl-mobile,
    large: $font-size-3xl-desktop
  ),
);

// ---------------------------------------------------------------
// Line Height Map
// Map to handle the line heights
// ---------------------------------------------------------------

$font-line-height: (
  s: $font-line-height-s,
  m: $font-line-height-m
);

// ---------------------------------------------------------------
// Letter Spacing Map
// Map to handle the letter spacing
// ---------------------------------------------------------------

$font-letter-spacing: (
  s: $font-letter-spacing-s,
  m: $font-letter-spacing-m,
  l: $font-letter-spacing-l
);

// ---------------------------------------------------------------
// Font Scale Map
// Map to handle matching up the font scale for sizes,
// line heights, and letter spacings
// ---------------------------------------------------------------

$font-scale: (
  h1: (
    size: 3xl,
    line-height: s,
    letter-spacing: l
  ),
  h2: (
    size: 2xl,
    line-height: s,
    letter-spacing: l
  ),
  h3: (
    size: xl,
    line-height: s,
    letter-spacing: l
  ),
  h4: (
    size: l,
    line-height: m,
    letter-spacing: m
  ),
  h5: (
    size: l,
    line-height: m,
    letter-spacing: m
  ),
  h6: (
    size: m,
    line-height: m,
    letter-spacing: m
  ),
  intro: (
    size: l,
    line-height: m,
    letter-spacing: m
  ),
  long-copy: (
    size: l,
    line-height: m,
    letter-spacing: m
  ),
  body: (
    size: m,
    line-height: m,
    letter-spacing: m
  ),
  note: (
    size: s,
    line-height: m,
    letter-spacing: s
  ),
  tiny: (
    size: xs,
    line-height: m,
    letter-spacing: s
  ),
);

// ---------------------------------------------------------------
// Convert strip font size units
// ---------------------------------------------------------------

@function font-strip-units($number) {
  @return $number / ($number * 0 + 1);
}

// ---------------------------------------------------------------
// Convert px based font to em
// Usage:
// font-to-rem(px, base-size)
// ---------------------------------------------------------------

@function font-to-rem($pixels, $base: $font-size-default) {
  $base: font-strip-units($base) / 100 * 16px;
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  @return $pixels / $base * 1rem;
}

// ---------------------------------------------------------------
// Font Scale Functions
// Iterates through the map
// ---------------------------------------------------------------

@function font-family($family) {
  @return map-get($font-family, $family);
}

@function font-size($scale, $size: large) {
  @return font-to-rem(map-get(map-get($font-size, $scale), $size));
}

@function font-line-height($scale) {
  @return map-get($font-line-height, $scale);
}

@function font-letter-spacing($scale) {
  @return map-get($font-letter-spacing, $scale);
}

/*@function font-scale($scale, $size: l) {
    @return map-get(map-get(map-get($font-scale, $scale), size), $size);
}*/

// ---------------------------------------------------------------
// Font Scale Mixin
// Mixing to easily apply the correct font scale size
//
// Usage:
// @include font-scale(xl);
// ---------------------------------------------------------------

@mixin font-scale($scale, $important: null) {
  font-size: font-size(map-get(map-get($font-scale, $scale), size), small) #{$important};
  @include breakpoint(m) {
    font-size: font-size(map-get(map-get($font-scale, $scale), size), large) #{$important};
  }
  line-height: font-line-height(
      map-get(map-get($font-scale, $scale), line-height)
    )
    #{$important};
  letter-spacing: font-letter-spacing(
      map-get(map-get($font-scale, $scale), letter-spacing)
    )
    #{$important};
  transition: font 0.15s ease-in-out;
}

.button {
    @include button();
  
    &.is-spinner {
      color: transparent;
  
      &::before {
        @include particle(xl);
        animation: infinite-spinning 2s infinite linear;
        content: url('/assets/img/spinner.svg');
        display: block;
        left: calc(50% - 10px);
        position: absolute;
        top: calc(50% - 10px);
      }
    }
  
    &--primary {
      @include button-color(primary);
    }
  
    &--primary-reversed {
      @include button-color(primary-reversed);
    }
  
    &--secondary {
      @include button-color(secondary);
    }
  
    &--secondary-reversed {
      @include button-color(secondary-reversed);
    }
  
    &--previous {
      @include button-color(previous);
    }
  
    &--continue {
      @include button-color(continue);
    }
  
    &--register {
      @include button-color(register);
    }
  
    &--submit {
      @include button-color(submit);
    }
  
    &--text {
      @include button-color(text);
      @include button-size(auto);
      padding-left: spacing(zero);
      padding-right: spacing(zero);
  
      &::after {
        background-color: color(teal, darker);
        bottom: 8px;
        content: '';
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;  
        transition: opacity .1s ease-in-out;
      }
  
      &:hover {
        box-shadow: none;
        transform: none;
    
        &::after {
          opacity: 1;
        }
      }
    }
  
    &--small {
      @include button-size(s);
    }
  
    &--medium {
      @include button-size(m);
    }
  
    &--large {
      @include button-size(l);
    }
  
    &--full {
      @include button-size(full);
      @include container(s);
    }
  
    &__icon {
      @include particle(s);
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .button-group {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @include breakpoint(s) {
      flex-direction: row;
    }
  
    & .button:not(:first-child) {
      margin-top: spacing(m);
  
      @include breakpoint(s) {
        margin-left: spacing(m);
        margin-top: spacing(zero);
      }
    }
  }
// ---------------------------------------------------------------
// Spacing Scale Map
// Modular scale increasing at multiples of 1.5
// ---------------------------------------------------------------

$spacing-scale: (
  auto: $spacing-auto,
  zero: $spacing-zero,
   3xs: $spacing-3xs,
   2xs: $spacing-2xs,
    xs: $spacing-xs,
     s: $spacing-s,
     m: $spacing-m,
     l: $spacing-l,
    xl: $spacing-xl,
   2xl: $spacing-2xl
);

// ---------------------------------------------------------------
// Spacing Scale Function
// Iterates through the spacing scale map
//
// Usage:
//   spacing(xl);
// ---------------------------------------------------------------

@function spacing($scale) {
  // Convert the scale value to a string
  $scale: inspect($scale);

  // Check if a negative scale value
  $is-negative: str-index($scale, '-');
  //$is-negative: str-slice($scale, 1, 1);

  @if $is-negative {
    // Get the length of the scale
    // that includes the '-' value
    $scale-length: str-length($scale);

    // Slice the scale to remove the '-' value
    $scale-slice: str-slice($scale, 2, $scale-length);

    // Get the value from the map
    $scale-value: map-get($spacing-scale, $scale-slice);

    // Return the negative value from the map
    @return (-$scale-value);
  } @else {
    // Return the positive value from the map
    @return map-get($spacing-scale, $scale);
  }
}

// ---------------------------------------------------------------
// Helper spacing mixins
// Handles 6 primary spacing options
// 	1. equal padding/margin on an element
// 	2. offset padding/margin on an element (i.e. left/right or top/bottom
// 	   have different padding)
//  3. unique spacing for when padding/margin is unique on all sides
//  4. stacking for setting bottom margins for consistent
//     vertical rhythm
//  5. stacking and centering the element for setting bottom
//     margins and centering a container
//  6. inline for setting right margins
//---------------------------------------------------------------

// Usage: (top, right, left, bottom padding/margin)
// @include spacing-equal(margin, s);

@mixin spacing-equal($type, $value) {
  #{$type}: spacing($value);
  // @warn "Unknown `#{$value}` in $spacing-scale map";
}

// Usage: (top & botton padding/margin, left & right padding/margin)
// @include spacing-offset(margin, zero, auto);

@mixin spacing-offset($type, $top-bottom, $left-right) {
  #{$type}: spacing($top-bottom) spacing($left-right);
  // @warn "Unknown `#{$top-bottom}` & `#{$left-right}` in $spacing-scale map";
}

// Usage: (unique spacing on all sides)
// @include spacing-unique(margin, l, xl, s, m);

@mixin spacing-unique($type, $top, $right, $bottom, $left) {
  #{$type}: spacing($top) spacing($right) spacing($bottom) spacing($left);
}

// Usage: (bottom margin only)
// @include spacing-stack(xl);

@mixin spacing-stack($bottom-margin) {
  margin-bottom: spacing($bottom-margin);
  // @warn "Unknown `#{$bottom-margin}` in $spacing-scale map";
}

// Usage: (bottom margin)
// @include spacing-stack-center(xl);

@mixin spacing-stack-center($bottom-margin) {
  margin: 0 auto spacing($bottom-margin);
  // @warn "Unknown `#{$bottom-margin}` in $spacing-scale map";
}

// Usage: (right-margin)
// @include spacing-inline(xs);

@mixin spacing-inline($right-margin) {
  margin-right: spacing($right-margin);
  // @warn "Unknown `#{$right-margin}` in $spacing-scale map";
}

// ---------------------------------------------------------------
// Containers Map
// ---------------------------------------------------------------

$containers: (
   2xs: $container-2xs,
    xs: $container-xs,
     s: $container-s,
     m: $container-m,
     l: $container-l,
    xl: $container-xl,
   2xl: $container-2xl,
   3xl: $container-3xl,
  full: $container-full
);

// ---------------------------------------------------------------
// Function to handle consuming the containers map
// ---------------------------------------------------------------

@function container($container) {
  @return map-get($containers, $container);
}

// ---------------------------------------------------------------
// General Container Mixin
// Sets basic container values as a mixin based on the
// Sass map of container values above
//
// Usage:
//    @include container(l);
// ---------------------------------------------------------------

@mixin container($container) {
  @if map-has-key($containers, $container) {
    max-width: container($container);
  }
}

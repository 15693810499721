// ---------------------------------------------------------------
// Particles Scale Map
// Particle / par·ti·cle /: A basic UI element
// Modular scale mostly increasing by 2px increments
// ---------------------------------------------------------------

$particles: (
  3xs: $particle-3xs,
  2xs: $particle-2xs,
   xs: $particle-xs,
    s: $particle-s,
    m: $particle-m,
    l: $particle-l,
   xl: $particle-xl,
  2xl: $particle-2xl,
  3xl: $particle-3xl,
  4xl: $particle-4xl,
  5xl: $particle-5xl
);

// ---------------------------------------------------------------
// Function to handle consuming the particles map
// ---------------------------------------------------------------

@function particle($scale) {
  @return map-get($particles, $scale);
}

// ---------------------------------------------------------------
// General Particles Mixin
// Sets basic ui element values (width and height) based on the
// Sass map of particles values above
//
// Usage:
//    @include particle(l);
// ---------------------------------------------------------------

@mixin particle($scale) {
  height: particle($scale);
  width: particle($scale);
}

// ---------------------------------------------------------------
// Border Radius Scale Map
// Modular scale increasing at multiples of 3
// ---------------------------------------------------------------

$border-radiuses: (
  s: $border-radius-s,
  m: $border-radius-m,
  l: $border-radius-l,
  xl: $border-radius-xl,
);

// ---------------------------------------------------------------
// Function to handle consuming the border radius map
// ---------------------------------------------------------------

@function border-radius($radius) {
  @return map-get($border-radiuses, $radius);
}

// ---------------------------------------------------------------
// General Border Radius Mixin
// Sets border-radius, equal on all sides, based on the
// Sass map of radius values above
//
// Usage:
//    @include border-radius(m);
// ---------------------------------------------------------------

@mixin border-radius($radius) {
  @if map-has-key($border-radiuses, $radius) {
    border-radius: border-radius($radius);
  }
}

// ---------------------------------------------------------------
// Top Border Radius Mixin
// Sets border-radius, on the top sides, based on the
// Sass map of radius values above
//
// Usage:
//    @include border-radius-top(m);
// ---------------------------------------------------------------

@mixin border-radius-top($radius) {
  @if map-has-key($border-radiuses, $radius) {
    border-top-left-radius: border-radius($radius);
    border-top-right-radius: border-radius($radius);
  }
}

// ---------------------------------------------------------------
// Right Border Radius Mixin
// Sets border-radius, on the right sides, based on the
// Sass map of radius values above
//
// Usage:
//    @include border-radius-right(m);
// ---------------------------------------------------------------

@mixin border-radius-right($radius) {
  @if map-has-key($border-radiuses, $radius) {
    border-top-right-radius: border-radius($radius);
    border-bottom-right-radius: border-radius($radius);
  }
}

// ---------------------------------------------------------------
// Bottom Border Radius Mixin
// Sets border-radius, on the bottom sides, based on the
// Sass map of radius values above
//
// Usage:
//    @include border-radius-bottom(m);
// ---------------------------------------------------------------

@mixin border-radius-bottom($radius) {
  @if map-has-key($border-radiuses, $radius) {
    border-bottom-right-radius: border-radius($radius);
    border-bottom-left-radius: border-radius($radius);
  }
}

// ---------------------------------------------------------------
// Left Border Radius Mixin
// Sets border-radius, on the left sides, based on the
// Sass map of radius values above
//
// Usage:
//    @include border-radius-left(m);
// ---------------------------------------------------------------

@mixin border-radius-left($radius) {
  @if map-has-key($border-radiuses, $radius) {
    border-top-left-radius: border-radius($radius);
    border-bottom-left-radius: border-radius($radius);
  }
}

// ---------------------------------------------------------------
// Unique Border Radius Mixin
// Sets border-radius, on all the sides, based on the
// Sass map of radius values above. Each corner may have
// a different radius.
//
// Usage:
//    @include border-radius-unique(m, l, s, s);
// ---------------------------------------------------------------

@mixin border-radius-unique(
  $radius-top-left,
  $radius-top-right,
  $radius-bottom-right,
  $radius-bottom-left
) {
  border-radius: border-radius($radius-top-left)
    border-radius($radius-top-right) border-radius($radius-bottom-right)
    border-radius($radius-bottom-left);
}


@mixin font-size-utility-classes {
    @each $size, $result in $font-size {
      &-#{$size} {
        font-size: map-get($result, 'small');
        @media(min-width: $breakpoint-m){
            font-size:  map-get($result, 'large');
        } 
      }
    }
  }

  @mixin text-color-utility-classes {
    @each $color, $result in $colors {
      &-#{$color} {
        color: color($color)
      }
    }
  }


.text {
  @include font-size-utility-classes;
  @include text-color-utility-classes;
}


$border-radius-s: 2px;
$border-radius-m: 6px;
$border-radius-l: 18px;
$border-radius-xl: 54px;
$breakpoint-xs: 480px;
$breakpoint-s: 576px;
$breakpoint-m: 692px;
$breakpoint-l: 830px;
$breakpoint-xl: 996px;
$breakpoint-2xl: 1195px;
$breakpoint-3xl: 1434px;
$button-size-s-width: 130px;
$button-size-s-height: 44px;
$button-size-m-width: 180px;
$button-size-m-height: 44px;
$button-size-l-width: 220px;
$button-size-l-height: 44px;
$button-size-auto-width: auto;
$button-size-auto-height: 44px;
$button-size-full-width: 100%;
$button-size-full-height: 44px;
$color-transparent: transparent;
$color-white: #ffffff;
$color-blue: #005984;
$color-blue-dark: #014464;
$color-teal-light-x: #f3fbfd;
$color-teal-light: #e0f6fb;
$color-teal: #a7e1ea;
$color-teal-dark: #26bcd7;
$color-teal-dark-x: #00789e;
$color-gray-light-x: #f2f4f4;
$color-gray-light: #dfe4e4;
$color-gray: #d7d9da;
$color-gray-dark: #696969;
$color-gray-dark-x: #4c4c4c;
$color-red: #9e1b32;
$color-orange: #f78e1e;
$color-yellow: #ffd200;
$color-green: #54b948;
$color-green-dark: #00853f;
$color-cream: #ffeebb;
$color-tan: #e7d2ad;
$container-2xs: 300px;
$container-xs: 400px;
$container-s: 500px;
$container-m: 600px;
$container-l: 670px;
$container-xl: 806px;
$container-2xl: 1200px;
$container-3xl: 1200px;
$container-full: 100%;
$font-size-default: 62.5%;
$font-size-xs-mobile: 11px;
$font-size-xs-desktop: 12px;
$font-size-s-mobile: 13px;
$font-size-s-desktop: 14px;
$font-size-m-mobile: 15px;
$font-size-m-desktop: 16px;
$font-size-l-mobile: 17px;
$font-size-l-desktop: 18px;
$font-size-xl-mobile: 20px;
$font-size-xl-desktop: 27px;
$font-size-2xl-mobile: 24px;
$font-size-2xl-desktop: 40px;
$font-size-3xl-mobile: 29px;
$font-size-3xl-desktop: 60px;
$font-line-height-s: 1.2;
$font-line-height-m: 1.6;
$font-letter-spacing-s: .16px;
$font-letter-spacing-m: .4px;
$font-letter-spacing-l: 1px;
$icon-s: 30px;
$icon-m: 48px;
$icon-l: 76px;
$icon-xl: 122px;
$icon-2xl: 190px;
$logo-s: 25px;
$logo-s-subbrand: 34px;
$logo-m: 41px;
$logo-m-subbrand: 57px;
$particle-3xs: 8px;
$particle-2xs: 10px;
$particle-xs: 12px;
$particle-s: 14px;
$particle-m: 16px;
$particle-l: 18px;
$particle-xl: 20px;
$particle-2xl: 24px;
$particle-3xl: 28px;
$particle-4xl: 36px;
$particle-5xl: 44px;
$spacing-auto: auto;
$spacing-zero: 0;
$spacing-3xs: 3px;
$spacing-2xs: 5px;
$spacing-xs: 7px;
$spacing-s: 11px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 36px;
$spacing-2xl: 54px;
$z-index-xs: -1;
$z-index-s: 0;
$z-index-m: 1;
$z-index-l: 2;
$z-index-xl: 3;
$z-index-2xl: 4;

// ---------------------------------------------------------------
// Breakpoints Map
// Modular scale increasing at multiples of 1.2
// ---------------------------------------------------------------

$breakpoints: (
   xs: $breakpoint-xs,
    s: $breakpoint-s,
    m: $breakpoint-m,
    l: $breakpoint-l,
   xl: $breakpoint-xl,
  2xl: $breakpoint-2xl,
  3xl: $breakpoint-3xl
);

// ---------------------------------------------------------------
// Function to handle consuming the breakpoints map
// ---------------------------------------------------------------

@function breakpoint($breakpoint, $rule: null) {
  @if $rule == 'max-width' {
    @return (map-get($breakpoints, $breakpoint) - 1px);
  } @else {
    @return map-get($breakpoints, $breakpoint);
  }
}

// ---------------------------------------------------------------
// General Breakpoint Mixin
// Sets basic breakpoint values as a mixin based on the
// Sass map of breakpoint values in the theme.scss
// @media rule defaults to 'min-width'
//
// Usage:
//    @include breakpoint(l, min-width) {
//        ...
//    }
// ---------------------------------------------------------------

@mixin breakpoint($breakpoint, $rule: min-width) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (#{$rule}: breakpoint($breakpoint, #{$rule})) {
      @content;
    }
  } @else {
    @warn "Unknown `#{$breakpoint}` in $breakpoints map";
  }
}

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/theming';
@import './app/global-styles/variables/_all.scss';
@import './app/global-styles/base/imports.scss';
@include mat-core();

$primary: mat-palette($duke-primary);
$accent: mat-palette($duke-accent);
$warn: mat-palette($duke-warn);
$theme: mat-light-theme($primary, $accent, $warn);

@font-face {
    font-family: 'NewsGothBTRoman';
    src: url('./assets/fonts/NewsGothBTRoman.ttf') format("truetype");
}

@font-face {
    font-family: 'NewsGothBTBold';
    src: url('./assets/fonts/NewsGothBTBold.ttf') format("truetype");
}

$custom-typography: mat-typography-config(
    $font-family: 'NewsGothBTRoman'
);

@include mat-core($custom-typography);
@include angular-material-theme($theme);



html {
  font-size: $font-size-default;
}

body {
  @include font-scale(body);
  font-family: font-family(roman);
  font-weight: normal;
  color: color(typography);
}

.de-h1,
.de-h2,
.de-h3,
.de-h4,
.de-h5,
.de-h6 {
  @include spacing-unique(margin, zero, zero, m, zero);
  font-weight: 300;
  color: color(typography, dark);

  @at-root a & {
    color: color(typography, link);
  }
}

.de-h1 {
  @include font-scale(h1);
  color: color(blue);
}

.de-h2 {
  @include font-scale(h2);
}

.de-h3 {
  @include font-scale(h3);
}

.de-h4 {
  @include font-scale(h4);
}

.de-h5 {
  @include font-scale(h5);
}

.de-h6 {
  @include font-scale(h6);
}

p,
ul,
ol,
dl {
  @include spacing-stack(m);
  color: color(typography);
}

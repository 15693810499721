// ---------------------------------------------------------------
// Button Sizes Map
// Map to handle the button sizes
// ---------------------------------------------------------------

$button-sizes: (
  s: (
    width: $button-size-s-width,
    height: $button-size-s-height
  ),
  m: (
    width: $button-size-m-width,
    height: $button-size-m-height
  ),
  l: (
    width: $button-size-l-width,
    height: $button-size-l-height
  ),
  auto: (
    width: $button-size-auto-width,
    height: $button-size-auto-height
  ),
  full: (
    width: $button-size-full-width,
    height: $button-size-full-height
  )
);

// ---------------------------------------------------------------
// Button Colors Map
// Map to handle the button colors
// ---------------------------------------------------------------

$button-colors: (
  primary: (
    background: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-blue,
    ),
    border: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-blue,
    ),
    text: (
      base: $color-white,
      disabled: $color-white,
      hover: $color-white,
    ),
  ),
  primary-reversed: (
    background: (
      base: $color-white,
      disabled: $color-white,
      hover: $color-teal-light,
    ),
    border: (
      base: $color-white,
      disabled: $color-white,
      hover: $color-teal-light,
    ),
    text: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-teal-dark-x,
    ),
  ),
  secondary: (
    background: (
      base: $color-transparent,
      disabled: $color-transparent,
      hover: $color-teal-light-x,
    ),
    border: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-teal-dark-x,
    ),
    text: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-teal-dark-x,
    ),
  ),
  secondary-reversed: (
    background: (
      base: $color-transparent,
      disabled: $color-transparent,
      hover: $color-transparent,
    ),
    border: (
      base: $color-white,
      disabled: $color-white,
      hover: $color-white,
    ),
    text: (
      base: $color-white,
      disabled: $color-white,
      hover: $color-white,
    ),
  ),
  text: (
    background: (
      base: $color-transparent,
      disabled: $color-transparent,
      hover: $color-transparent,
    ),
    border: (
      base: $color-transparent,
      disabled: $color-transparent,
      hover: $color-transparent,
    ),
    text: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-teal-dark-x,
    )
  ),
  previous: (
    background: (
      base: $color-transparent,
      disabled: $color-transparent,
      hover: $color-teal-light-x,
    ),
    border: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-teal-dark-x,
    ),
    text: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-teal-dark-x,
    ),
  ),
  continue: (
    background: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-blue,
    ),
    border: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-blue,
    ),
    text: (
      base: $color-white,
      disabled: $color-white,
      hover: $color-white,
    ),
  ),
  register: (
    background: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-blue,
    ),
    border: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-blue,
    ),
    text: (
      base: $color-white,
      disabled: $color-white,
      hover: $color-white,
    ),
  ),
  submit: (
    background: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-blue,
    ),
    border: (
      base: $color-teal-dark-x,
      disabled: $color-teal-dark-x,
      hover: $color-blue,
    ),
    text: (
      base: $color-white,
      disabled: $color-white,
      hover: $color-white,
    ),
  )
);

// ---------------------------------------------------------------
// Button Functions
// Iterates through the map
// ---------------------------------------------------------------

@function button-size($scale, $dimension: width) {
  @return map-get(map-get($button-sizes, $scale), $dimension);
}

@function button-color($variant: primary, $attribute: background, $state: base) {
  @return map-get(map-get(map-get($button-colors, $variant), $attribute), $state);
}

// ---------------------------------------------------------------
// Helper button mixins
// Handles several button options
// 	1. button default
// 	2. size for defining the dimensions of the button
//  3. color for defining the colors of the background,
//     border, and text color for various states of
//     the button
//---------------------------------------------------------------

@mixin button($variant: primary, $size: m) {
  @include border-radius(s);
  @include font-scale(note);
  @include spacing-equal(padding, s);
  background: button-color($variant, background, base);
  border: 1px solid button-color($variant, border, base);
  color: button-color($variant, text, base);
  cursor: pointer;
  display: inline-block;
  height: button-size($size, height);
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  width: button-size($size, width);
  transition: all .1s ease-in-out;

  &:hover {
    background-color: button-color($variant, background, hover);
    border-color: button-color($variant, border, hover);
    box-shadow: 0 2px 4px 0 rgba(color(gray, darker), 0.4);
    color: button-color($variant, text, hover);
    text-decoration: none;
    transform: translateY(-2px);
  }

  &:disabled,
  &[aria-disabled]  {
    background-color: button-color($variant, background, disabled);
    border-color: button-color($variant, border, disabled);
    box-shadow: none;
    opacity: 0.4;
    pointer-events: none;
    transform: translateY(0);
  }

  &.is-spinner {
    pointer-events: none;
  }
}

@mixin button-size($scale) {
  height: button-size($scale, height);
  width: button-size($scale, width);
}

@mixin button-color($variant) {
  background-color: button-color($variant, background, base);
  border-color: button-color($variant, border, base);
  color: button-color($variant, text, base);

  &:hover {
    background-color: button-color($variant, background, hover);
    border-color: button-color($variant, border, hover);
    color: button-color($variant, text, hover);
  }

  &:disabled,
  &[aria-disabled] {
    background-color: button-color($variant, background, disabled);
    border-color: button-color($variant, border, disabled);
    color: button-color($variant, text, disabled);
  }
}

/* You can add global styles to this file, and also import other style files */

@import "~@de-electron/design-tokens/dist/web/__all";
@import "~@de-electron/component-library/src/global/scss/base/__imports";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/*Imported for Material, can be overwritten*/
@import "./assets/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import './theme.scss';

body {
    color: $color-gray-dark;

    .text-blue {
        color: $color-blue;
    }

    h1 {
        font-size: 32px;
        color: $color-teal-dark-x;
        margin-bottom: 15px;
    }

    h2 {
        font-size: 28px;
        color: $color-blue;
    }

    .de-login-wrapper {
        max-width: 400px;
        margin: auto;
    }

    .login-section {
        width: unset !important;
        min-width: 300px !important;
        margin: 0 !important;
        padding: 0 !important;
    
        h2,
        .login-img,
        .login-form-label {
            display: none;
        }

        .login-off-container {
            background-color: lightgray;
        }
    
        .login-form-input {
            margin-bottom: 12px !important;
            padding: 24px 24px 24px 36px !important;
            border: 1px solid $color-gray !important;
        }
    
        .login-form-icn {
            position: relative !important;
            top: 32px !important;
            left: 12px !important;
        }
    
        .login-btn-togglePass {
            top: 32px !important;
            right: 12px !important;
            padding: 0 !important;
        }
    
        .login-btn-section {
            margin: 24px;
        }
    
        .login-error-message-content {
            color: red;
        }
    }
}

.table {
    border: 1px solid $color-gray-dark;
    font-size: 16px;
    margin-top: 2em;
    margin-bottom: 2em;

    .row {
        padding: 0.5em;
        border-bottom: 1px solid $color-gray-dark;
    }

    :last-child {
        border: 0;
    }
}

.fa {
    color: $color-teal-dark-x;
}

.fa-check {
    color: $color-green;
    font-size: 1.75em;
}

.fa-question-circle {
    cursor: pointer;
}

// de-electron overrides
.de-stepper__text {
    color: darken(#d7d9da, 25) !important;
}

.de-button {
    &:focus {
        outline: 0;
    }
}

.cdk-global-overlay-wrapper {
    overflow: scroll;
}

@media(max-width: $breakpoint-xs) {
    body {
        .de-site-header-brand {
            align-items: center;
        }

        .de-site-header-title {
            max-width: 200px;
            white-space: normal;
            line-height: 2rem;
        }
    }
}
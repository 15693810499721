@font-face {
  font-family: 'NewsGothicBT-Roman';
  src: url('../../../assets/fonts/NewsGothBTRoman.ttf') format("truetype");
}

@font-face {
  font-family: 'NewsGothicBT-Bold';
  src: url('../../../assets/fonts/NewsGothBTBold.ttf') format("truetype");
}

@font-face {
  font-family: 'NewsGothLtBTLightItalic';
  src: url('../../../assets/fonts/NewsGoth Lt BT Light Italic.ttf') format("truetype");
}

@font-face {
  font-family: 'NewsGothBTLight';
  src: url('../../../assets/fonts/NewsGoth BT Light.ttf') format("truetype");
}

// @font-face {
//   font-family: 'NewsGothicBT-Light';
//   src: url('https://www.duke-energy.com/assets/site/fonts/newsgothicbt-light.woff2')
//       format('woff2'),
//     url('https://www.duke-energy.com/assets/site/fonts/newsgothicbt-light.woff')
//       format('woff');
// }
